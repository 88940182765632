import { createGlobalStyle } from 'styled-components'
import { alpha } from '@material-ui/core'
import { theme } from './theme'

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Apex Rounded';
    src: url('/fonts/ApexRounded-Medium.eot');
    src:
      url('/fonts/ApexRounded-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/ApexRounded-Medium.woff2') format('woff2'),
      url('/fonts/ApexRounded-Medium.woff') format('woff'),
      url('/fonts/ApexRounded-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Apex Rounded';
    src: url('/fonts/ApexRounded-Book.eot');
    src:
      url('/fonts/ApexRounded-Book.eot?#iefix') format('embedded-opentype'),
      url('/fonts/ApexRounded-Book.woff2') format('woff2'),
      url('/fonts/ApexRounded-Book.woff') format('woff'),
      url('/fonts/ApexRounded-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Apex Rounded';
    src: url('ApexRounded-BookItalic.eot');
    src:
      url('ApexRounded-BookItalic.eot?#iefix') format('embedded-opentype'),
      url('ApexRounded-BookItalic.woff2') format('woff2'),
      url('ApexRounded-BookItalic.woff') format('woff'),
      url('ApexRounded-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Apex Rounded';
    src: url('/fonts/ApexRounded-Bold.eot');
    src:
      url('/fonts/ApexRounded-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/ApexRounded-Bold.woff2') format('woff2'),
      url('/fonts/ApexRounded-Bold.woff') format('woff'),
      url('/fonts/ApexRounded-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  ol {
    list-style: none;
    counter-reset: list-counter;

    li {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      counter-increment: list-counter;
      font-size: ${theme.typography.pxToRem(17)};
      color: ${theme.palette.text.secondary};
      line-height: 1.5;
      margin-bottom: ${theme.typography.pxToRem(12)};

      &::before {
        content: counter(list-counter) ".";
        display: inline-block;
        padding-right: ${theme.typography.pxToRem(15)};
        font-size: ${theme.typography.pxToRem(14)};
        font-weight: 500;
      }

      ${theme.breakpoints.up('xs')} {
        font-size: ${theme.typography.pxToRem(15)};
      }

      ${theme.breakpoints.up('sm')} {
        font-size: ${theme.typography.pxToRem(17)};
      }
    }
  }

  ul {
    list-style: none;

    li {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      font-size: ${theme.typography.pxToRem(17)};
      color: ${theme.palette.text.secondary};
      line-height: 1.5;
      margin-bottom: ${theme.typography.pxToRem(12)};

      &::before {
        content: "•";
        display: inline-block;
        color: ${theme.palette.secondary.main};
        padding-right: ${theme.typography.pxToRem(12)};
        font-size: ${theme.typography.pxToRem(19)};
      }

      ${theme.breakpoints.up('xs')} {
        font-size: ${theme.typography.pxToRem(15)};
      }

      ${theme.breakpoints.up('sm')} {
        font-size: ${theme.typography.pxToRem(17)};
      }
    }
  }

  a {
    color: ${theme.palette.secondary.dark};
    text-decoration: underline;
    font-size: ${theme.typography.pxToRem(17)};

    &:hover {
      color: ${theme.palette.secondary.main};
      cursor: pointer;
    }

    ${theme.breakpoints.up('xs')} {
      font-size: ${theme.typography.pxToRem(15)};
    }

    ${theme.breakpoints.up('sm')} {
      font-size: ${theme.typography.pxToRem(17)};
    }
  }

  p,
  p.MuiTypography-body2 {
    margin-bottom: 2.5em;
  }

  blockquote {
    background: #fff4dc;
    color: ${theme.palette.text.secondary};
    padding-top: ${theme.typography.pxToRem(60)};
    padding-right: ${theme.typography.pxToRem(20)};
    padding-bottom: ${theme.typography.pxToRem(80)};
    padding-left: ${theme.typography.pxToRem(20)};
    font-weight: 400;
    line-height: 1.5;
    position: relative;

    &::before {
      position: absolute;
      left: ${theme.typography.pxToRem(20)};
      top: ${theme.typography.pxToRem(25)};
      height: ${theme.typography.pxToRem(24)};
      width: ${theme.typography.pxToRem(24)};
      content: "";
      background: url('/images/quote.svg');
      background-size: contain;
      background-repeat: no-repeat;
      color: ${theme.palette.secondary.main};
      display: inline-block;
    }

    p {
      left: ${theme.typography.pxToRem(20)};
      top: ${theme.typography.pxToRem(25)};
      height: ${theme.typography.pxToRem(24)};
    }

    cite {
      color: ${theme.palette.text.primary};
      position: absolute;
      left: ${theme.typography.pxToRem(20)};
      bottom: ${theme.typography.pxToRem(30)};
    }

    ${theme.breakpoints.up('md')} {
      padding-top: ${theme.typography.pxToRem(20)};
      padding-left: ${theme.typography.pxToRem(60)};
      padding-bottom: ${theme.typography.pxToRem(60)};

      &::before {
        left: ${theme.typography.pxToRem(20)};
        top: ${theme.typography.pxToRem(25)};
      }

      cite {
        left: ${theme.typography.pxToRem(60)};
        bottom: ${theme.typography.pxToRem(20)};
      }
    }
  }

  .MuiPopover-paper {
    > ul > li {
      font-size: ${theme.typography.pxToRem(16)};

      &::before {
        display: none;
      }
    }
  }

  table {
    th {
      tr {
        border-bottom: 2px solid ${props => props.theme.palette.secondary.light};
        background-color: ${props => alpha(props.theme.palette.secondary.light, 0.15)};
      }
    }
  }

  .MuiTableContainer-root {
    > table {
      thead > tr {
        th {
          text-align: left;
          border-bottom: 2px solid ${props => props.theme.palette.secondary.light};
          background-color: ${props => alpha(props.theme.palette.secondary.light, 0.15)};
        }

        th:not(.MuiTableCell-root) {
          padding: 16px;
          font-weight: 500;
        }
      }

      tbody > tr {
        th:not(.MuiTableCell-root),
        td:not(.MuiTableCell-root) {
          border-bottom: 1px solid rgba(224, 224, 224, 1);
          padding: 16px;
        }
      }
    }
  }

  #__next {
    overflow-x: hidden;
  }
`